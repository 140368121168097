.Footer {
  height: 5vh;
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.Small-Logo {
  width: 32px;
  margin-left: 20px;
}

.Footer-Copyright {
  margin: 0 10px;
  font-weight: bold;
}

.Footer-Link {
  margin: 0 10px;
  font-weight: bold;
  height: auto;
  &:hover {
    opacity: 0.85;
  }
}

@primary-color: #1E2E5D;@secondary-color: #EF4237;