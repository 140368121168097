.Landing {
  min-height: 500px;
  height: 95vh;
}

.Main {
  background: url(../images/background/Landing.jpg);
  background-size: cover;
  height: 100%;
}

.Elements {
  display: flex;
  flex-flow: column wrap;
  justify-items: space-evenly;
  padding: 40px 0px 0px 40px;
}

.Tagline {
  color: white;
  font-weight: bold;
  width: 300px;
  text-align: left;
  line-height: 120%;
  margin-bottom: 30px;
}

.Logo {
  width: 150px;
}

.Sign-In-Link {
  width: 0;
}

.Sign-In {
  width: 250px;
  &:hover {
    opacity: 0.9;
  }
  margin: auto;
}

@primary-color: #1E2E5D;@secondary-color: #EF4237;